.site-header {
    @extend .position-fixed,
    .w-100;
    left: 0;
    top: 0;
    padding: 15px 0;
    border-bottom: rgba(0, 0, 0, .3) 1px solid;
    z-index: 20;
    transition: all .5s;

    .container-fluid {
        @extend .d-flex,
        .justify-content-between,
        .align-items-center;
    }

    .site-logo {
        margin: 15px 0;
        width: 12.625rem;
    }

    .site-nav {
        &-wrap {
            .mob-nav-opener {
                @extend .d-block,
                .d-lg-none;
            }

            .mob-nav-overlay {
                @include media-breakpoint-down(md) {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(0, 0, 0, .3);
                    z-index: 99;
                    display: none;
                }
            }
        }

        @extend .d-lg-flex,
        .align-items-center;

        .mob-nav-closer {
            @extend .d-block,
            .d-lg-none;
        }

        ul {
            @extend .list-unstyled,
            .m-0,
            .p-0,
            .d-lg-flex;

            li {
                @extend .text-uppercase;
                font-size: 1.125rem;
                letter-spacing: 1px;

                a {
                    color: #FFF;
                }

                &:first-child {
                    a {
                        color: #000;
                    }
                }

                &.active {
                    a {
                        font-weight: 700;
                    }
                }
            }
        }

        .auth-btns {
            @extend .d-lg-flex;

            .btn {
                @extend .btn-block;

            }

            @include media-breakpoint-up(xl) {
                padding-left: 30px;

                .btn {
                    width: 145px;
                }
            }

            @include media-breakpoint-up(lg) {
                .btn {
                    margin-top: 0 !important;
                    margin-left: 10px;
                }
            }

            @include media-breakpoint-only(lg) {
                .btn {
                    font-size: 14px;
                    width: 100px;
                }
            }
        }

        @media screen and (min-width: 1400px) {
            ul {
                li {
                    padding: 0 45px;
                }
            }
        }

        @media screen and (min-width: 1200px) and (max-width:1399px) {
            ul {
                li {
                    padding: 0 1.5rem;
                }
            }
        }

        @include media-breakpoint-only(lg) {
            flex-wrap: wrap;

            ul {
                order: 1;
                width: 100%;
                justify-content: flex-end;
                margin-top: .5rem !important;

                li {
                    margin-left: 4.5rem;
                }
            }

            .auth-btns {
                width: 100%;
                justify-content: flex-end;
                order: 0;
            }
        }

        @include media-breakpoint-down(md) {
            background-color: #FFF;
            position: fixed;
            left: -300px;
            top: 0;
            bottom: 0;
            width: 280px;
            padding: 1.5rem;
            z-index: 100;
            transition: all .5s;

            &.toggleMobNav {
                left: 0;
            }

            ul {
                margin: 2rem 0 !important;

                li {
                    margin: 1rem 0;

                    a {
                        color: #000;
                        padding: .5rem 0;
                    }
                }
            }
        }
    }

    &.headerShrink {
        @extend .bg-white;
        padding: 0;

        .site-nav {
            ul {
                li {
                    a {
                        color: #000;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        background-color: #FFF;
        padding: 0;
    }
}

.page-banner {
    @extend .position-relative;
    padding-top: 7.625rem;
    background-color: #f1f1f1;
    z-index: 3;
    height: 312px;

    @include media-breakpoint-only(md) {
        padding-top: 6rem;
    }

    @include media-breakpoint-down(md) {
        overflow: hidden;
        height: auto;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 6.525rem;
    }

    .container-fluid {
        @extend .position-relative,
        .h-100,
        .d-flex;

        .page-banner-img {
            @extend .position-absolute;
            right: 0;
            top: -122px;
            max-width: inherit;

            @media screen and (min-width: 1400px) {
                margin-right: -178px;
            }

            @media screen and (min-width: 1200px) and (max-width:1399px) {
                margin-right: -305px;
            }

            @include media-breakpoint-only(lg) {
                margin-right: -604px;
            }

            @include media-breakpoint-down(md) {
                top: -92px;
            }
        }

        @include media-breakpoint-down(md) {
            height: auto !important;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }
    }

    &-sm-icons {
        @extend .d-none,
        .d-lg-flex,
        .align-items-center;
        align-self: flex-end;
        margin: 2.5rem 0;

        h4,
        a {
            @extend .my-2;
        }

        h4 {
            @extend .text-uppercase;
            font-weight: 700;
            //margin-right: 3rem;
        }

        a {
            @extend .d-flex,
            .align-items-center,
            .justify-content-center,
            .rounded-circle,
            .ml-3;
            color: #000;
            width: 2.75rem;
            height: 2.75rem;
            border: #000 1px solid;
            font-size: 1.25rem;
            transition: all .5s;

            &:hover {
                background-color: #000;
                color: #fff;
                text-decoration: none;
            }
        }

        @media screen and (min-width: 1400px) {
            h4 {
                margin-right: 3rem;
            }
        }
    }

    &-content {
        align-self: flex-end;
        width: 100%;
        margin-left: auto;
        z-index: 2;
        position: relative;
        color: #FFF;

        h1 {
            @extend .m-0;
            font-size: 4.5rem;
            font-family: 'sf_compact_displaythin';
            line-height: 1.1;

            strong {
                font-family: 'sf_compact_displaybold';
            }
        }

        @media screen and (min-width: 1400px) {
            width: 930px;

            h1 {
                font-size: 6rem;
            }
        }

        @media screen and (min-width: 1200px) and (max-width:1399px) {
            width: 810px;
        }

        @include media-breakpoint-only(lg) {
            width: 510px;

            h1 {
                font-size: 3.5rem;
            }
        }

        @include media-breakpoint-down(md) {
            align-self: center;
            text-align: center;
        }
    }

    &.home-banner {
        .home-banner-img {
            @extend .d-none,
            .d-lg-block;
            position: absolute;
            right: 0;
            bottom: -24px;

            @media screen and (min-width: 1400px) {
                margin-right: 733px;
            }

            @media screen and (min-width: 1200px) and (max-width:1399px) {
                /* max-width: 350px;
                bottom: -16px; */
                margin-right: 660px;
            }

            @include media-breakpoint-only(lg) {
                right: inherit;
                left: 3%;
                max-width: 35%;
                bottom: 0;
            }
        }

        .home-banner-content {
            position: relative;
            z-index: 2;
            width: 100%;

            h1 {
                font-family: 'sf_compact_displaythin';
                font-size: 3.375rem;
                color: #FFF;

                strong {
                    display: block;
                    color: $primary-color;
                    font-family: 'sf_compact_displaybold';
                }
            }

            .btn {
                @extend .d-inline-flex,
                .align-items-center,
                .justify-content-center,
                .p-0,
                .mt-4;
                width: 210px;
                height: 3.375rem;
                font-size: 1.25rem;
            }

            @include media-breakpoint-up(lg) {
                padding-left: 1.5rem;
                max-width: 550px;
                margin-left: auto;
                align-self: center;
            }

            @include media-breakpoint-down(md) {
                padding: 2rem 1rem;
                text-align: center;
            }
        }

        .page-banner-sm-icons {
            align-self: flex-start;

            h4 {
                margin-right: 0;
            }

            @media screen and (min-width: 1400px) {
                position: absolute;
                left: -140px;
                top: 300px;
                transform: rotate(90deg);

                a {
                    transform: rotate(-90deg);
                }
            }
        }

        @media screen and (min-width: 1400px) {
            //height: 747px;

            .home-banner-img {}
        }

        @media screen and (min-width: 1200px) {
            height: 747px;
        }

        @include media-breakpoint-only(lg) {
            height: 660px;
        }

        @include media-breakpoint-down(lg) {
            overflow: hidden;
        }
    }
}