$primary-color:#ee0000;
$theme-colors: ("primary": #ee0000, "black": #000000);
@import "supporting/bootstrap/bootstrap";
@import "supporting/fontawesome/fontawesome";
@import "supporting/fontawesome/brands";
@import "supporting/fontawesome/solid";
@import "supporting/fontawesome/regular";
@import "supporting/global";
@import "supporting/site-header";
@import "supporting/site-footer";
@import "supporting/page-home";
@import "supporting/page-contact";
@import "supporting/page-network";
@import "supporting/page-dedicated-servers";
@import "supporting/page-server-configuration";