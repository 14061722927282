.counters-sec {
    .container-fluid {
        @extend .position-relative;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &:after {
            @extend .position-absolute,
            .d-block,
            .bg-white;
            content: '';
            left: 0;
            top: 0;
            bottom: 0;
            width: 5000px;
            box-shadow: 0px 32px 50px rgba(0, 0, 0, .21);
        }
    }

    ul {
        @extend .m-0,
        .p-0,
        .list-unstyled,
        .d-flex,
        .text-center,
        .position-relative,
        .flex-wrap,
        .justify-content-center;
        z-index: 2;

        li {
            @extend .my-4;
            padding: 0 1rem;

            @include media-breakpoint-up(lg) {
                width: 20%;
            }

            @include media-breakpoint-down(md) {
                width: 33.33%;
            }

            @include media-breakpoint-down(sm) {
                width: 50%;
            }
        }
    }

    .counter-col {
        &-icon {
            margin-bottom: 2rem;
            height: 5.25rem;

            img {
                max-height: 5.25rem;
            }
        }

        h3 {
            @extend .text-primary;
            font-size: 3rem;
            font-family: 'sf_compact_displaybold';
        }

        h4 {
            @extend .text-uppercase,
            .mb-0;
            font-weight: 900;
        }

        @include media-breakpoint-up(lg) {
            &+.counter-col {
                border-left: 1px solid #bcbcbc;
            }
        }

        @include media-breakpoint-down(lg) {
            h3 {
                font-size: 2.5rem;
            }

            h4 {
                font-size: 1.25rem;
            }
        }
    }
}

.features-sec {
    @extend .position-relative;
    padding-top: 5rem;
    padding-bottom: 5rem;

    &:after {
        @extend .d-none,
        .d-md-block,
        .position-absolute,
        .bg-primary;
        content: '';
        right: 53%;
        top: -30rem;
        bottom: 0;
        width: 5000px;
    }

    .container-fluid {
        @extend .position-relative;
        z-index: 1;
    }

    .fea-desc {
        @extend .bg-primary,
        .text-white;

        h2 {
            @extend .text-uppercase;
            font-size: 3rem;
            font-weight: 300;

            strong {
                @extend .d-block;
                font-weight: 700;
            }
        }

        p {
            margin: 2rem 0 0 0;
            font-size: 1.5rem;
            text-align: justify;
        }

        @include media-breakpoint-down(sm) {
            padding: 1.5rem;
            border-radius: 1rem;
        }
    }

    .fea-list {
        @extend .m-0,
        .p-0,
        .list-unstyled;

        li {
            @extend .d-flex;
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;

            &+li {
                border-top: #bcbcbc 1px solid;
            }
        }

        &-icon {
            width: 5rem;
            margin-right: 1.5rem;

            img {
                max-height: 5rem;
            }
        }

        &-info {
            width: calc(100% - 6.5rem);
            align-self: center;

            h3 {
                font-size: 1.875rem;
                font-weight: 900;
            }

            p {
                margin: 0;
                font-size: 1.125rem;
            }
        }
    }
}

.about-sec {
    background: url('../images/about-sec-bg.jpg') no-repeat center;
    background-size: cover;

    @include media-breakpoint-up(xl) {
        padding-top: 6rem;
        padding-bottom: 7rem;
    }

    @include media-breakpoint-down(lg) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .container-fluid {
        @extend .d-xl-flex,
        .justify-content-between;
    }

    .about-sec-col {
        padding: .5rem 0;

        @include media-breakpoint-up(xl) {
            width: 48%;
            max-width: 660px;
        }

        h2,
        &-list {
            margin: 2.75rem 0;
        }

        h2 {
            font-size: 3rem;
            font-weight: 300;

            strong {
                @extend .d-block;
                font-weight: 900;
            }
        }

        &-list {
            @extend .bg-white;
            box-shadow: 0px 32px 50px rgba(0, 0, 0, .21);

            ul {
                @extend .list-unstyled,
                .m-0,
                .p-0;

                li {
                    padding: 1rem 2rem;

                    &+li {
                        border-top: #dbdbdb 1px solid;
                        font-size: 1.125rem;
                    }

                    @include media-breakpoint-up(xl) {
                        padding: 1rem 4rem;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 1rem 2rem;
                    }
                }
            }
        }
    }
}

.pop-serv-sec {
    @extend .position-relative,
    .text-white;

    &:before,
    &:after {
        @extend .d-none,
        .d-md-block,
        .position-absolute,
        .h-100;
        content: '';
        width: 5000px;
        top: 0;
    }

    &:before {
        @extend .bg-primary;
        left: 50%;
    }

    &:after {
        //@extend .bg-black;
        background-color: #130c3f;
        right: 50%;
    }

    &-img {
        @extend .position-absolute,
        .d-none,
        .d-xl-block;
        right: 50%;
        bottom: -27px;
        margin-right: -110px;
        z-index: 1;
    }

    .container-fluid {
        @extend .position-relative,
        .d-md-flex,
        .justify-content-between;
        z-index: 1;
    }

    h2 {
        @extend .text-uppercase,
        .my-3;
        font-size: 3rem;
        font-weight: 300;

        strong {
            @extend .d-block;
            font-weight: 900;
        }
    }

    &-left {
        .pop-serv-tabs-nav {
            @extend .position-relative;
            padding-left: 55px;

            h4 {
                @extend .position-absolute,
                .text-uppercase,
                .m-0;
                transform: rotate(90deg);

                @include media-breakpoint-up(lg) {
                    letter-spacing: 5px;
                    left: -110px;
                    top: 170px;
                }

                @include media-breakpoint-only(md) {
                    letter-spacing: 4px;
                    left: -88px;
                    top: 151px;
                }

                @include media-breakpoint-down(sm) {
                    letter-spacing: 3px;
                    left: -78px;
                    top: 105px;
                }
            }

            .nav {
                padding: 3rem 0;
                max-width: 238px;

                a {
                    @extend .text-white,
                    .border,
                    .border-white,
                    .text-center,
                    .font-weight-bold,
                    .my-3,
                    .text-uppercase;
                    border-width: 2px !important;
                    font-size: 1.5rem;
                    border-radius: 2rem;
                    transition: all .5s;

                    &.active {
                        @extend .border-primary;
                    }
                }
            }
        }
    }

    &-right {

        h3,
        h4 {
            @extend .my-3;
        }

        h3 {
            font-size: 2.25rem;
        }

        ul {
            @extend .m-0,
            .py-3,
            .list-unstyled;
            max-width: 360px;

            li {
                @extend .py-3,
                .d-flex;
                font-size: 1.5rem;

                strong {
                    width: 50%;
                }

                &+li {
                    @extend .border-top,
                    .border-white;
                }
            }
        }

        .pop-serv-price-row {
            @extend .d-xl-flex,
            .justify-content-between,
            .align-items-end;

            h3,
            .btn {
                @extend .mb-4;
            }

            h3 {
                big {
                    @extend .d-block;
                    font-size: 3rem;
                    font-weight: 900;
                }
            }

            .btn {
                width: 210px;
            }
        }
    }

    @include media-breakpoint-up(sm) {}

    @include media-breakpoint-up(md) {
        padding: 4rem 0;
        /* &-left,
        &-right {
            margin: 1rem 0;
        } */

        &-left {
            width: 45%;
            max-width: 630px;
        }

        &-right {
            width: 50%;
            max-width: 580px;
            padding-left: 15px;
        }
    }

    @include media-breakpoint-up(lg) {
        &-right {
            width: 45%;
        }
    }

    @include media-breakpoint-up(xl) {
        &-right {
            ul {
                padding: 3rem 0 !important;
            }
        }
    }

    @include media-breakpoint-down(sm) {

        &-left,
        &-right {
            padding: 2rem;
            margin: 0 -15px;
        }

        &-left {
            background-color: #130c3f;

            .pop-serv-tabs-nav {
                .nav {
                    padding: 1rem 0;
                }
            }
        }

        &-right {
            background-color: $primary-color;
        }
    }
}