.site-footer {
    @extend .position-relative,
    .text-white;
    background-color: #1c1c1c;
    padding: 2.5rem 0;
    font-size: 1.25rem;
    z-index: 3;

    &:after {
        @extend .position-absolute,
        .h-100,
        .d-block;
        top: 0;
        right: 60px;
        left: 0;
        content: '';
        background: url('../images/footer-bg.png') no-repeat top right;
    }

    .container-fluid {
        @extend .position-relative;
        z-index: 2;
    }

    a {
        @extend .text-reset;
    }

    .footer-logo {
        @extend .text-center;
        padding: 2.5rem 0;

        img {
            max-width: 339px;
            width: 21rem;
        }

        @include media-breakpoint-up(md) {
            padding: 4.5rem 0;
        }
    }

    .footer-nav {
        ul {
            @extend .list-unstyled,
            .m-0,
            .p-0,
            .text-center;

            li {
                display: inline-block;
                padding: .5rem 2rem;
                //font-weight: 700;

                a {
                    color: #FFF;
                }
            }
        }
    }

    .footer-contacts-bar {
        @extend .d-flex,
        .justify-content-between;
        margin-top: 3rem;

        @include media-breakpoint-up(md) {
            margin-top: 6rem;
        }

        .footer-sm-icons {
            @extend .d-flex,
            .align-items-center;

            h5 {
                @extend .mb-0,
                .mr-3;
            }

            a {
                margin: 0 1rem;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-top: 12rem;
        }
    }

    .copyrights-bar {
        @extend .bg-white,
        .p-3,
        .d-xl-flex,
        .align-items-center,
        .justify-content-center,
        .text-center;
        margin-top: 2rem;
        font-size: 1rem;
        color: #000;
        line-height: 1;

        p,
        &-nav {
            margin: .5rem 1.5rem;
        }

        &-nav {
            @extend .order-xl-1;

            a {
                @extend .position-relative,
                .d-inline-block,
                .text-reset;
                margin: .5rem 2rem;

                &:not(:last-child):after {
                    @extend .position-absolute;
                    content: '';
                    right: -2rem;
                    background-color: #000;
                    height: 100%;
                    width: 1px;
                }
            }
        }

        p {
            //@extend .order-lg-1;
        }
    }

    /* @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
    }

    @include media-breakpoint-down(lg) {
        .footer-contacts-bar h5 {
            font-size: 1rem;
        }
    } */

    @include media-breakpoint-down(lg) {
        .footer-contacts-bar {
            flex-wrap: wrap;
            justify-content: center !important;

            .footer-sm-icons,
            &>a {
                margin: .5rem 1rem;
            }
        }
    }
}