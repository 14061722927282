.pkgs-list-sec {
    padding: 2rem 0;

    &>.container-fluid {
        @extend .d-lg-flex;
    }

    &-info {
        @extend .position-relative,
        .text-white;

        h2 {
            font-family: 'sf_compact_displaythin';
            font-size: 3.75rem;

            strong {
                @extend .d-block;
                font-family: 'sf_compact_displaybold';
            }
        }

        p {
            font-size: 1.5rem;
            margin: 2rem 0;
        }

        &-inner {
            @extend .position-relative;
            z-index: 2;
        }

        &:after {
            @extend .position-absolute,
            .d-block,
            .bg-primary;
            content: '';
            right: 0;
            top: 0;
            bottom: -2rem;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                width: 5000px;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                border-radius: 1rem;
            }
        }

        @media screen and (min-width: 1400px) {
            width: calc(100% - 982px);
        }

        @media screen and (min-width: 1200px) and (max-width: 1399px) {
            width: calc(100% - 855px);
        }

        @include media-breakpoint-up(lg) {
            margin-top: -2rem;
            padding: 5rem 60px 5rem 0;
        }

        @include media-breakpoint-only(lg) {
            width: calc(100% - 554px);
            padding-top: 5rem;
        }

        @include media-breakpoint-down(md) {
            padding: 2rem;
        }
    }

    .pkgs-list-wrap {
        @extend .position-relative;
        z-index: 3;

        .pkg-box {
            @extend .bg-white,
            .position-relative,
            .my-5;
            box-shadow: 0px 32px 50px rgba(0, 0, 0, .21);
            padding: 1rem 3rem;

            &-head {
                @extend .d-sm-flex,
                .justify-content-between,
                .border-bottom,
                .pb-3,
                .mb-3;

                h4,
                h5 {
                    @extend .py-2,
                    .m-0;
                }

                h4 {
                    font-family: 'sf_compact_displaybold';
                }

                h5 {
                    font-family: 'sf_compact_displayregular';
                    font-size: 1.5rem;

                    strong {
                        @extend .text-primary;
                        font-family: 'sf_compact_displaybold';
                    }
                }
            }

            &-info {
                &-col {
                    @extend .d-flex,
                    .py-4;

                    img {
                        width: 2.5rem;
                        margin-right: 1.75rem;
                    }

                    h6 {
                        @extend .m-0;
                        font-family: 'sf_compact_displayregular';
                        font-size: 1.125rem;

                        strong {
                            @extend .d-block;
                            font-family: 'sf_compact_displaybold';
                        }
                    }
                }
            }

            .btn-order-pkg {
                width: 156px;
                position: absolute;

                @include media-breakpoint-up(lg) {
                    right: -155px;
                    transform: translateX(-50%) rotate(-90deg);
                    top: 50%;
                    margin-top: -21px;
                }

                @include media-breakpoint-down(md) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        @media screen and (min-width: 1400px) {
            width: 990px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1399px) {
            width: 872px;
        }

        @include media-breakpoint-only(lg) {
            width: 572px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -26px;
        }

        @include media-breakpoint-down(md) {
            .pkg-box {
                border-radius: 1rem;
            }
        }
    }
}