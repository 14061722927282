.contact-page-container {
    @include media-breakpoint-up(lg) {
        padding-top: 10rem;
    }

    @include media-breakpoint-down(md) {
        //margin: 1rem 0;
    }
}

.contact-page-sidebar {
    @extend .position-relative;
    box-shadow: 0 32px 50px rgba(0, 0, 0, .21);
    z-index: 3;

    &:before,
    &:after {
        @extend .position-absolute,
        .h-100;
        right: 0;
        top: 0;
        content: '';
    }

    &:before {
        background: url('../images/contact-sidebar-bg.jpg') repeat-x right top;
        background-size: contain;
        opacity: .15;
        z-index: 2;
    }

    &:after {
        background-color: $primary-color;
        z-index: 1;
    }

    &-inner {
        position: relative;
        z-index: 3;
        color: #FFF;
        padding: 1.5rem;

        a {
            @extend .text-reset;
        }

        h2 {
            font-size: 2.25rem;

            strong {
                font-weight: 700;
            }
        }

        p {
            font-size: 1.125rem;
        }
    }

    @include media-breakpoint-up(lg) {

        &:before,
        &:after {
            right: -3rem;
            width: 5000px;

        }

        /* &:before {
            left: -100%;
        }

        &:after {
            width: 5000px;
        } */

        &-inner {
            padding-top: 4rem;
            padding-bottom: 4rem;
            margin-top: -5rem;
        }
    }

    @include media-breakpoint-down(md) {
        border-radius: 1rem;
        overflow: hidden;
        margin: 2rem 0rem;

        &:after {
            width: 100%;
        }
    }
}

.contact-form-container {
    @extend .position-relative,
    .h-100;

    &:before {
        @extend .position-absolute,
        .bg-white,
        .d-block;
        width: 5000px;
        left: -654px;
        top: -10rem;
        bottom: 220px;
        content: '';
        z-index: 1;
        box-shadow: 0 32px 50px rgba(0, 0, 0, .21);
    }

    &:after {
        @extend .position-absolute;
        left: -115px;
        bottom: 0;
        content: '';
        background: url('../images/contact-page-banner-bottom.jpg') repeat-x left;
        background-size: auto;
        height: 220px;
        width: 5000px;
    }

    .contact-form {
        @extend .position-relative;
        z-index: 2;

        /* padding: 3rem 1rem; */
        p {
            font-size: 1.5rem;
        }

        .form-control {
            border: 0;
            background-color: #f1f1f1;
            font-family: 'sf_compact_displayregular';
        }

        .btn-send {
            width: 150px;
        }
    }

    @include media-breakpoint-down(md) {
        &:before {
            bottom: 0;
        }

        &:after {
            display: none;
        }

        .contact-form {
            margin: 1rem 0;
        }
    }
}