@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');

html {
    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        font-size: 14px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 12px;
    }
}

body {
    background-color: #f1f1f1;
    font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow-x: hidden;
    color: #000000;
}

img {
    max-width: 100%;

    &.embed-responsive-item {
        object-fit: cover;
    }
}

.mw-1570 {
    max-width: 1570px;
}

.btn {
    @extend .text-uppercase;
    border-radius: 2rem;
    font-weight: 700;
    font-size: 1.125rem;

    &-lg {
        @extend .d-inline-flex,
        .justify-content-center,
        .align-items-center;
        height: 3.375rem;
    }
}

.btn-white {
    @extend .bg-white;
}

.form-control {
    border-radius: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &-lg {
        height: calc(1.8em + 1rem + 2px);
    }
}

.serv-sec {
    @extend .py-5,
    .text-center;

    .serv-col {
        @extend .mx-auto;
        max-width: 400px;
        margin: 2rem 0;

        &-icon {
            height: 6.25rem;

            img {
                max-height: 6.25rem;
            }
        }

        h2 {
            font-weight: 900;
        }

        h4 {
            line-height: 1.5;
        }

        .btn {
            width: 75%;
            max-width: 230px;
        }

        @include media-breakpoint-up(xl) {

            .serv-col-icon,
            h2,
            h4 {
                margin: 3rem 0;
            }
        }

        @include media-breakpoint-down(lg) {

            .serv-col-icon,
            h2,
            h4 {
                margin: 1.5rem 0;
            }
        }
    }
}

.nss-tabs {
    .nav {
        @extend .border-bottom,
        .border-dark;

        li {
            font-size: 1.5rem;
            font-family: 'sf_compact_displaybold';

            a {
                @extend .d-flex,
                .align-items-center,
                .justify-content-center,
                .position-relative,
                .py-2;
                height: 100%;
                min-height: 5rem;
                line-height: 1.1;

                &:after {
                    @extend .position-absolute,
                    .w-100,
                    .d-block;
                    height: .5rem;
                    content: '';
                    left: 0;
                    bottom: -1px;
                    background-color: $primary-color;
                    opacity: 0;
                    transition: all .5s;
                }

                &.active {
                    color: $primary-color;

                    &:after {
                        opacity: 1;
                    }
                }

                &:hover {
                    text-decoration: none;
                }

                &:not(.active) {
                    color: #000;
                }
            }
        }
    }
}

.btn-group {
    .btn {
        @extend .border-top,
        .border-bottom;

        &:first-child {
            @extend .border-left;
            border-radius: 2rem 0 0 2rem;
        }

        &:last-child {
            @extend .border-right;
            border-radius: 0 2rem 2rem 0;
        }

        &.active {
            @extend .bg-primary,
            .text-white,
            .border-primary;
        }
    }
}

.serv-countries-map {
    @extend .position-relative,
    .text-white;
    background-color: #1c1c1c;

    .embed-responsive {
        min-height: 400px;
    }

    .map-tabs-wrap {
        @extend .position-absolute;
        left: 15px;
        bottom: 2rem;
        z-index: 11;

        //transform: translateX();
        h2,
        .btn {
            @extend .my-3;
        }

        h2 {
            font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 300;
            font-size: 3rem;
            text-transform: uppercase;

            strong {
                @extend .d-block;
                font-weight: 900;
            }
        }

        .btn {
            width: 100%;
            max-width: 250px;
        }

        @media screen and (min-width:1600px) {
            left: 12%;
        }

        /* @include media-breakpoint-up() {

        } */
    }
}