.server-config-content {
    @extend .bg-white,
    .py-5;

    h2 {
        @extend .text-center,
        .py-4;
        font-size: 3rem;
        font-family: 'sf_compact_displaythin';

        strong {
            font-family: 'sf_compact_displaybold';
        }
    }

    .region-selection {
        @extend .text-center,
        .pt-5,
        .pb-3;

        .btn-group {
            width: 100%;
            max-width: 300px;

            .btn {
                flex: 1;
            }
        }
    }

    .config-rows-wrap,
    .config-cols-wrap {
        &-inner {
            padding: 0 2.5rem;
            min-width: 500px;
        }

        .config-row,
        .config-col {
            @extend .py-4;

            h3 {
                @extend .d-flex,
                .align-items-center,
                .justify-content-center,
                .mt-4,
                .mb-5;
                font-size: 1.875rem;
                font-family: 'sf_compact_displaybold';

                img {
                    @extend .mr-3;
                    max-width: 2rem;
                }
            }

            .storage-type {
                @extend .text-center,
                .pb-5;

                .btn-group {
                    width: 100%;
                    max-width: 200px;

                    .btn {
                        flex: 1;
                    }
                }
            }
        }

        .config-row {
            max-width: 800px;
            margin: 0 auto;

            &+.config-row {
                @extend .border-top,
                .mt-4;
            }

            .nss-range-slider {
                //margin: 1rem 0;
            }
        }

        @include media-breakpoint-down(xs) {
            overflow-y: hidden;
        }
    }
}

.nss-range-slider {
    .slider.slider-horizontal {
        width: 100%;
        height: 25px;

        .slider-track {
            background-image: none;
            background-color: #b7b7b7;
            box-shadow: none;
            height: 6px;

            .slider-selection {
                &.tick-slider-selection {
                    @extend .bg-primary;
                    background-image: none;
                }
            }
        }

        .slider-tick-label-container {
            margin-top: 30px;

            .slider-tick-label {
                //@extend .text-left;
                color: #b7b7b7;
                cursor: pointer;

                strong {
                    @extend .d-block;
                }

                &.label-is-selection {
                    @extend .text-primary
                    /* ,
                    .bg-primary */
                    ;
                    /* border-radius: 1.5rem;
                    padding: 15px; */
                }
            }
        }

        .slider-tick-container {
            .slider-tick {
                @extend .bg-transparent;
                background-image: none !important;
                box-shadow: none;
            }
        }

        .slider-handle {
            background-image: none;
            background-color: $primary-color;
            width: 22px;
            height: 22px;
        }
    }

    &.memory-range-slider,
    &.storage-range-slider,
    &.bandwidth-range-slider,
    &.data-center-range-slider {
        .slider.slider-horizontal {
            .slider-tick-label-container {
                .slider-tick-label {
                    &.label-is-selection {
                        @extend .font-weight-bold;
                    }
                }
            }
        }
    }
}

.serv-config-cart-box {
    @extend .bg-primary,
    .mb-5,
    .text-white;
    margin-top: 5rem;
    padding: 3.75rem;

    @include media-breakpoint-down(sm) {
        padding: 2rem;
        border-radius: 1rem;
    }

    ul {
        @extend .list-unstyled,
        .m-0,
        .p-0;

        li {
            @extend .d-flex,
            .py-4;

            p {
                @extend .m-0;
                width: 70%;

                &:last-child {
                    width: 30%;
                }
            }
        }
    }

    h2 {
        font-family: 'sf_compact_displaybold';
        font-size: 3rem;
    }

    .btn {
        color: $primary-color;
        width: 80%;
        max-width: 250px;
        font-size: 1.5rem;
        font-family: 'sf_compact_displaybold';
    }
}