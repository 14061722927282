.network-page-intro {
    &>.container-fluid {
        @extend .position-relative;

        &:before {
            @extend .position-absolute,
            .bg-white,
            .d-block,
            .h-100;
            width: 5000px;
            left: 0;
            top: 0;
            content: '';
            z-index: 1;
            box-shadow: 0 32px 50px rgba(0, 0, 0, .21);
        }
    }

    .npi-inner {
        @extend .position-relative;
        z-index: 2;

        @include media-breakpoint-up(xl) {
            padding: 2rem 4rem;
        }

        @include media-breakpoint-down(lg) {
            padding: 2rem;
        }
    }

    h2,
    p {
        @extend .my-4;
    }

    h2 {
        font-size: 3rem;
    }

    p {
        font-size: 1.5rem;
    }

}

.network-tabs-sec {
    //@extend .py-4;
    padding: 5rem 0;

    .tab-content {
        padding: 2rem 0;
    }
}

.net-fea-col {
    @extend .d-flex;
    margin: 2rem 0;

    .nfc-icon {
        margin-right: 2rem;
        width: 4rem;

        img {
            max-width: 4rem;
            max-height: 4rem;
        }
    }

    .nfc-info {
        width: calc(100% - 6rem);

        h3 {
            font-family: 'sf_compact_displaybold';
            font-size: 2.25rem;
        }

        p {
            font-family: 'sf_compact_displayregular';
            font-size: 1.125rem;
        }
    }
}

.custom-serv-sec {
    padding: 6rem 0;

    .custom-serv-banner {
        @extend .d-md-flex,
        .px-5,
        .py-4;
        background: url('../images/custom-serv-bg.jpg') no-repeat center;
        background-size: cover;

        .csb-thumb,
        .csb-info {
            @extend .my-4,
            .text-white;
        }

        .csb-thumb {
            @extend .order-md-1,
            .text-center;
            align-self: center;
        }

        .csb-info {
            @extend .text-center,
            .text-md-left;
            font-family: 'sf_compact_displayregular';

            h3 {
                font-size: 3.75rem;

                strong {
                    font-family: 'sf_compact_displaybold';
                }
            }

            p {
                margin: 2rem 0;
                font-size: 1.875rem;
            }
        }

        @include media-breakpoint-up(md) {
            .csb-thumb {
                width: 40%;
            }

            .csb-info {
                margin-right: 2%;
                width: 60%;
            }
        }

        @include media-breakpoint-down(sm) {
            border-radius: 1rem;
        }
    }
}